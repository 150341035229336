import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ContactInfo } from '../../models/contact';
import { CallUsInfo } from '../../models/callUs';
import { Observable } from 'rxjs';
import { SliderItem } from '../../models/sliderItem.model';
import { environment } from '../../../environments/environment';

// import { SearchData } from '../../models/searchData';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export const BASE_URL: string = environment.BASE_URL;// + '/';

// tnc_preprod : 'http://ws.lmv.thenextclick.net/'
// preprod : https://lmvwebhmlapi.sogecap.socgen/
// prod : https://lmvweb.sogecap.socgen/

@Injectable({ providedIn: 'root' })
export class GlobalService {
  private prefix: string = BASE_URL + '/global/';
  private prefix1: string = BASE_URL + '/api/';

  constructor(private http: HttpClient) {}

  public getStructure() {
    return this.http.get(this.prefix + 'structure');
  }

  public getHeaderData() {
    return this.http.get(this.prefix1 + 'menus/main-menu-front');
  }

  public getAboutData() {
    return this.http.get(this.prefix + 'about');
  }

  public getNeedsByType(type: string) {
    return this.http.get(this.prefix + 'needs?type_need=' + type);
  }

  public getNeedBySlug(slug: string) {
    return this.http.get(this.prefix + 'needBySlug?slug=' + slug);
  }

  public getProductBySlug(slug: string) {
    return this.http.get(this.prefix + 'productBySlug?slug=' + slug);
  }

  public getProjectEntreprise() {
    return this.http.get(this.prefix + 'getProductList');
  }

  public getProjectsByNeed(needId: string) {
    return this.http.get(this.prefix + 'products/need?need=' + needId);
  }

  public getProjectsByCategory(categoryId: string) {
    return this.http.get(this.prefix + 'products?category=' + categoryId, {
      observe: 'response',
    });
  }

  public contactUs(info: ContactInfo) {
    const body = new FormData();
    body.append('name', info.name);
    body.append('tel', info.tel);
    body.append('from', info.from);
    body.append('object', info.object);
    body.append('message', info.message);
    return this.http.post(this.prefix + 'contact', body);
  }

  public callUs(info: CallUsInfo) {
    const body = new FormData();
    body.append('full_name', info.full_name);
    body.append('tel', info.tel);
    return this.http.post(this.prefix + 'rappeler', body);
  }

  public search(keyValue: string): Observable<any> {
    console.log('Search query sent to API:', keyValue);
    const searchUrl = `${this.prefix}recherche/resultat/${encodeURIComponent(
      keyValue
    )}`;

    return this.http.get<any>(
      this.prefix + 'search?key=' + encodeURI(keyValue)
    );
  }

  public getLegals(path:string) {
    return this.http.get(this.prefix + 'legals?slug=' + path);
  }

  public getPrivacyPolicy() {
    return this.http.get(
      `${BASE_URL}/politique-de-confidentialite?_format=json`
    );
  }

  public getCguPolicy() {
    return this.http.get(`${BASE_URL}/cgu?_format=json`);
  }

  public sendJoinUs(body: any) {
    // const body = new FormData();
    // body.append('name', info.name);
    // body.append('email', info.email);
    // body.append('phone', info.phone);
    // body.append('comment', info.comment);
    // body.append('CV', info.file);
    // body.append('firstName', info.firstName);
    // body.append('lastName', info.lastName);

    return this.http.post(this.prefix1 + 'joinus/create', body);
  }

  public getSiteMapXml() {
    window.location.href = BASE_URL + '/sitemap.xml';
  }

  public getDocuments() {
    return this.http.get(this.prefix + 'documentlist');
  }

  public getPopupMsg() {
    return this.http.get(this.prefix + 'popup-msg');
  }

  public getContactPopupMsg() {
    return this.http.get(this.prefix + 'contact-popup-msg');
  }
  getNewsList(page: number, length: number): Observable<any> {
    return this.http.get<any>(
      `${this.prefix1}news/list?page=${page}&length=${length}`
    );
  }

  getFeaturedArticle() {
    return this.http.get(this.prefix1 + 'news/featured');
  }

  getArticleData(articleId: number) {
    return this.http.get(this.prefix1 + 'news/${articleId}/data');
  }

  getContactHeader() {
    return this.http.get(
      this.prefix1 + 'blocks/header-contact-page?_format=json'
    );
  }

  getSelfIntroductionTypes() {
    return this.http.get(
      this.prefix1 + 'taxonomoies/contact-types?_format=json'
    );
  }

  getContratTypes() {
    return this.http.get(
      this.prefix1 + 'taxonomies/types-contrat?_format=json'
    );
  }

  getContactSubjects() {
    return this.http.get(
      this.prefix1 + 'taxonomoies/contact-subjects?_format=json'
    );
  }

  sendContactForm(data: any) {
    return this.http.post(this.prefix1 + 'contacts/create', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public getArticleDetails(id: number): Observable<any> {
    return this.http.get(`${BASE_URL}/api/news/${id}/data`);
  }
  public getSliderItems(): Observable<SliderItem[]> {
    return this.http.get<SliderItem[]>(
      `${BASE_URL}/api/blocks/slider-home-page?_format=json`
    );
  }
  public getFooterMenu() {
    return this.http.get(this.prefix1 + 'menus/menu-footer');
  }
  public getSocialMediaLinks() {
    return this.http.get(this.prefix1 + 'menus/socialmedia');
  }

  public getGlobalDocumentList() {
    return this.http.get(this.prefix1 + 'global/documentlist');
  }

  getAboutWhoAreWeHeader() {
    return this.http.get(
      this.prefix1 + 'blocks/header-quisommesnous-page?_format=json'
    );
  }

  getAboutKeyFigures() {
    return this.http.get(this.prefix1 + 'blocks/chiffres-cles?_format=json');
  }
  getLegalNoticeHeader(path:string) {
    if (path == 'confidentialite') {
      return this.http.get(
        this.prefix1 + 'blocks/politique-de-confidentialite?_format=json'
      );
    }
    if (path == 'cgu') {
      return this.http.get(this.prefix1 + 'blocks/cgu?_format=json');
    }
    return this.http.get(
      this.prefix1 + 'blocks/header-mentionlegales-page?_format=json'
    );
  }

  public getOrganigramme() {
    return this.http.get(this.prefix1 + '/organigramme');
  }

  getOrganigrammeHeader() {
    return this.http.get(
      this.prefix1 + 'blocks/header-organigramme-page?_format=json'
    );
  }

  getNousRejoindreHeader() {
    return this.http.get(
      this.prefix1 + 'blocks/header-nous-rejoindre-page?_format=json'
    );
  }

  getNousRejoindreCondidature() {
    return this.http.get(
      this.prefix1 + 'blocks/bloc-candidature-nous-rejoindre-page?_format=json'
    );
  }

  public getEntreprisesSliderItems(): Observable<SliderItem[]> {
    return this.http.get<SliderItem[]>(
      `${this.prefix1}/blocks/slider-entreprise-page?_format=json`
    );
  }

  getNousRejoindreSubBody = () => {
    return this.http.get(this.prefix1 + 'blocks/cartes-candidats?_format=json');
  }
}
